<template>
  <component
    :is="blok.tag_type || 'p'"
    :id="tagId"
    v-editable="blok"
    :class="[
      customMarginClass,
      paragraphBoldClass,
      fontStyleClass,
      textColorClass,
      positionMobileClass,
      positionClass,
    ]"
    :style="[maxWidth]"
  >
    <span>{{ blok.prefix }}</span
    ><AnimatedCounter :value="animatedNumber" /><span>{{ blok.suffix }}</span>
  </component>
</template>

<script lang="ts" setup>
import type { SbAnimatedCounterStoryblok } from '@/types'
import { slugify } from '@/utils/helpers'

interface Props {
  blok: SbAnimatedCounterStoryblok
}
const animatedNumber = computed(() => {
  return props.blok.text && parseInt(decodeAndReplaceText(props.blok.text))
})
const { decodeAndReplaceText } = useTextModifiers()
const props = defineProps<Props>()

const paragraphBoldClass = computed(() => {
  return props.blok.is_bold && 'paragraph--bold'
})
const customMarginClass = computed(() => {
  return `mb-${props.blok?.custom_margin}` ?? ''
})
const fontStyleClass = computed(() => {
  return props.blok.font?.font?.value ?? ''
})
const textColorClass = computed(() => {
  return props.blok?.font?.color_name
    ? `text-${props.blok.font.color_name}`
    : 'none'
})
const positionClass = computed(() => {
  return `text-position--${props.blok.position}` || 'text-position--left'
})
const positionMobileClass = computed(() => {
  return `text-position-mobile--${props.blok.position_mobile}` || null
})
const maxWidth = computed(() => {
  if (props.blok?.max_width) {
    return {
      maxWidth: `${props.blok.max_width}px`,
    }
  }

  return {
    maxWidth: 'none',
  }
})
const tagId = computed(() => {
  if (props.blok.tag_type === 'h2' || props.blok.tag_type === 'h3') {
    const slug = slugify(decodeAndReplaceText(props.blok.text as string))
    return slug.replace(/[^a-zA-Z ]/g, '')
  }
  return null
})
</script>

<style lang="scss" scoped>
.text-position {
  margin-left: 0;
  margin-right: 0;

  &--center {
    text-align: center;
  }

  &--left {
    text-align: left;
  }

  &--right {
    text-align: right;
  }
}

.text-position-mobile {
  &--center {
    @media (max-width: $breakpoint-lg) {
      text-align: center;
    }
  }

  &--left {
    @media (max-width: $breakpoint-lg) {
      text-align: left;
    }
  }

  &--right {
    @media (max-width: $breakpoint-lg) {
      text-align: right;
    }
  }
}
</style>
